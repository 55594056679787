<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Фотогалерея</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin" style="text-align: center">
            <p>
              <img src="/files/fotogallery/vorota_na_terriroriyu.jpg"
                   title="КПП. Въезд на территорию (бывший Автокрановый завод)" align="top"> <br>
              <b> Въезд на территорию (бывший Автокрановый завод)</b>
            </p>
            <p>
              <img src="/files/fotogallery/banner_na_stene.jpg" title="Баннер Техавтотест" align="top"> <br>
              <b> Баннер на стене нашего пункта ТО. Во время следования от КПП к нам он должен быть слева по ходу
                движения</b>
            </p>
            <p>
              <img src="/files/fotogallery/vorota_krupno_s_znakom.jpg" title="Въездные ворота Техавтотест" align="top">
              <br>
              <b> Наши въездные ворота</b>
            </p>
            <p>
              <img src="/files/fotogallery/ceh_interier1.jpg" title="Интерьер цеха" align="top"> <br>
              <b> Это наш пункт техосмотра</b>
            </p>
            <p>
              <img src="/files/fotogallery/stend_tormoz.jpg" title="Монитор измерительного стенда" align="top"> <br>
              <b>Монитор измерительного стенда</b>
            </p>
            <p>
              <img src="/files/fotogallery/ceh_interier2.jpg" title="Интерьер цеха" align="top"> <br>
              <b> На осмотре джип</b>
            </p>
            <p>
            </p>
            <p>
              <img src="/files/fotogallery/ceh_interier3.jpg" title="Интерьер цеха" align="top"> <br>
              <b> На осмотре легковушка</b>
            </p>
            <p>
              <img src="/files/fotogallery/avtokran.jpg" title="Интерьер цеха" align="top"> <br>
              <b> На ТО заезжает автокран</b>
            </p>
            <p>
              <img src="/files/fotogallery/ugol_potreb.jpg" title="Стенд потребителя" align="top"> <br>
              <b> Уголок потребителя</b>
            </p>
            <br>

            <img src="/files/fotogallery/IMG_8094.jpg" align="top"> <br>
            <img src="/files/fotogallery/IMG_8099.jpg" align="top"> <br>
            <img src="/files/fotogallery/IMG_8101.jpg" align="top"> <br>
            <img src="/files/fotogallery/IMG_8103.jpg" align="top"> <br>
            <img src="/files/fotogallery/IMG_8110.jpg" align="top"> <br>
            <img src="/files/fotogallery/PHOTO-2019-12-04-12-11-27.jpg" align="top"> <br></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foto"
}
</script>

<style scoped>

</style>